import React from "react"
import GoTop from "./GoTop"
import CookieConsent from "react-cookie-consent"

const Layout = ({ children }) => {
    return (
        <>
        <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
        We use cookies to improve user experience and analyze website traffic. By clicking “Accept“, you agree to our website's cookie use as described in our <a style={{color: '#c679e3'}} href='/policy'>Privacy Policy  </a>.
</CookieConsent>
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
        </>
    )
}
export default Layout